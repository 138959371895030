'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var _$1 = _interopDefault(require('lodash'));
var Cookies = _interopDefault(require('js-cookie'));
var dinero = _interopDefault(require('dinero.js'));
var moment = _interopDefault(require('moment'));
var ManyKeysMap = _interopDefault(require('many-keys-map'));

global.activeRequestsCount = 0;

var api = {
  async request({uri, body={}}, {token, shouldAlert=true, isPolling=false, files}={}) {
    if (!isPolling) global.activeRequestsCount += 1;

    var isReactNative = typeof(navigator) !== 'undefined' && navigator.product === 'ReactNative';
    var NODE_ENV = process.env.NODE_ENV;

    if (typeof(__DEV__) !== 'undefined' && !__DEV__ && NODE_ENV === 'development') {
      NODE_ENV = 'production';
    }

    var domain = {
      development: 'http://localhost:3301',
      production: 'https://symbolic-frameworks-api.herokuapp.com'
    }[NODE_ENV];

    if (global.API_DOMAIN || process.env.REACT_APP_API_DOMAIN || process.env.API_DOMAIN) {
      domain = global.API_DOMAIN || process.env.REACT_APP_API_DOMAIN || process.env.API_DOMAIN;
    }
    else if (process.env.REACT_APP_LOCAL_IP || process.env.LOCAL_IP) {
      domain = `http://${process.env.REACT_APP_LOCAL_IP || process.env.LOCAL_IP}:3301`;
    }

    var url = `${domain}${uri}`;
    var requestParams = {method: 'post', mode: 'cors'};

    body.token = body.token || token || global.sessionToken || Cookies.get('sessionToken');
    body.appVersion = global.APP_VERSION;
    body.appBuildNumber = global.APP_BUILD_NUMBER;
    body.isWeb = global.IS_WEB;
    body.appKey = global.APP_KEY;

    if (body.isWeb) body.hostname = window.location.hostname;

    if (files) {
      requestParams.body = new FormData();

      _$1.forEach(files, (file, f) => requestParams.body.append(f, file));

      _$1.forEach(body, (value, key) => typeof(value) !== 'undefined' && requestParams.body.append(key, typeof(value) === 'object' ? JSON.stringify(value) : value));
    }
    else {
      requestParams.body = JSON.stringify(body);
      requestParams.headers = {'Content-Type': 'application/json'};
    }

    try {
      var response = await fetch(url, requestParams);
      var jsonResponse = await response.json();
    }
    catch (e) {
      var response = {ok: false};
      var jsonResponse = {errors: [{message: e.message}]};
    }

    if (!isPolling) global.activeRequestsCount -= 1;

    if (!response.ok) {
      var message = _$1.join(_$1.map(jsonResponse.errors, 'message'), ', ');

      if (message === 'Failed to fetch') message = 'There was an issue connecting to our server. Please check your connection and try again shortly.';

      if (((shouldAlert && !isReactNative) || _$1.includes(message, 'out of date')) && typeof(alert) !== 'undefined') alert(message);

      console.log(jsonResponse);

      throw new Error(message);
    }

    return jsonResponse;
  },

  async resources(resources, options) {
    var response = await api.request({uri: '/resources', body: {resources}}, options);

    return response;
  },

  async act(actionKey, resourceKey, params={}, options) {
    var response = await api.resources({[actionKey]: {[resourceKey]: params}}, options);

    return _$1.get(response, `data.resources.${actionKey}.${resourceKey}`);
  },

  get(...args) {
    return api.act('get', ...args);
  },

  create(resourceKey, propsData={}, ...args) {
    return api.act('create', resourceKey, Array.isArray(propsData) ? _$1.map(propsData, props => ({props})) : {props: propsData}, ...args);
  },

  update(...args) {
    return api.act('update', ...args);
  },

  destroy(...args) {
    return api.act('destroy', ...args);
  }
};

var date = {};

date.formatDate = (momentInstance) => {
  if (!momentInstance) return '';

  var formatString = momentInstance.year() !== new Date().getFullYear() ? 'M/D/YY' : 'MMM D';

  return momentInstance.format(formatString);
};

date.formatDatetime = (momentInstance, {short=false}={}) => {
  var now = new Date();
  var format = short ? 'M/D/YY' : 'M/D/YY h:mma'; //standard long form - m/d/y 00:00am
  var timeDifference = _$1.round(Math.abs(momentInstance.diff(now) / 1000), 0);
  var secondsInOneDay = 60 * 60 * 24;

  if (timeDifference < secondsInOneDay) {
    format = 'h:mma'; // today - 00:00am
  }
  else if (now.getFullYear() === momentInstance.year()) {
    format = short ? 'M/D' : 'M/D h:mma'; // this year - m/d 00:00am
  }

  return momentInstance.format(format);
};

var colors = {};

colors.all = [
  //browns
  '#F4BB86',
  '#F7DE8F',
  '#EDD9C4',

  //reds
  '#FFAAAA',
  '#F6C0C0',
  '#DDBEBE',

  //purples
  '#A8A9F0',
  '#CDB4EB',
  '#E4C6E2',

  //blues
  '#9DBBEF',
  '#B7D2FF',
  '#BBD9E5',

  //greens
  '#C6E7E2',
  '#BEE2BE',
  '#D0D48C',
];

colors.fun = [
  '#ABC0DB',
  '#FACD55',
  '#C8D4A3',
  '#A9E1D3',
  '#FB9FC3',
  '#F89A90',
  '#B8B3FF',
];

colors.colorFor = (arg) => {
  var hue;

  if (typeof(arg) === 'object') {
    var {status, user, org} = arg;

    if (user) {
      hue = user.hue;
      arg = user.id - 1;
    }
    else if (org) {
      hue = org.hue;
      arg = org.id - 1;
    }
    else {
      var id = arg.userId || arg.orgId || arg.id || 1;

      arg = id - 1;
    }
  }

  var color = '';

  if (status) {
    if (status === 'archived') color = '#CCCCCC';
    else if (status === 'pending') color = '#F7DE8F';
    else if (status === 'planning') color = '#CCBEDD';
    else if (status === 'ready') color = '#D0D48C';
    else if (status === 'active') color = '#BAC4E3';
    else if (status === 'complete') color = '#AFD298';
  }
  else if (arg.key) {
    color = '#E99898';
  }
  else if (typeof(arg) === 'number') {
    var hueIsValid = hue && typeof(hue) === 'number' && hue > 0 && hue <= 360;

    if (!hueIsValid) {
      var colorCount = 30;
      var maxHue = 360;

      hue = (arg % colorCount) * (maxHue / colorCount);
    }

    color = `hsl(${hue}, ${60}%, ${75}%)`;
  }

  return color;
};

var libEvent = {
  keyPressed(event, key) {
    var keyCode = event.keyCode;
    var pressed = false;

    if (key === 'left') pressed = keyCode === 37;
    if (key === 'up') pressed = keyCode === 38;
    if (key === 'right') pressed = keyCode === 39;
    if (key === 'down') pressed = keyCode === 40;

    if (key === 'c') pressed = keyCode === 67;
    if (key === 's') pressed = keyCode === 83;
    if (key === 'v') pressed = keyCode === 86;
    if (key === 'x') pressed = keyCode === 88;
    if (key === 'y') pressed = keyCode === 89;
    if (key === 'z') pressed = keyCode === 90;
    if (key === '+') pressed = keyCode === 187;
    if (key === '-') pressed = keyCode === 189;

    if (key === 'enter') pressed = keyCode === 13;
    if (key === 'ctrlcmd') pressed = (event.ctrlKey || event.metaKey || event.which === 22 || event.which === 224);
    if (key === 'alt') pressed = (event.altKey);
    if (key === 'esc') pressed = keyCode === 27;
    if (key === 'tab') pressed = keyCode === 9;
    if (key === 'shift') pressed = event.shiftKey;
    if (key === 'backspace') pressed = keyCode === 8;
    if (key === 'space') pressed = keyCode === 32;
    if (key === 'delete') pressed = keyCode === 8 || keyCode === 46;

    return pressed;
  },

  numberKeyPressed(event) {
    var numberKeyCodes = [49, 50, 51, 52, 53, 54, 55, 56, 57, 48];
    var keyCode = event.keyCode;

    return _.includes(numberKeyCodes, keyCode);
  }
};

var time = {};

time.scales = [
  {abbreviation: 'm', matchStrings: ['mi'], title: 'minute', minutes: 1, maxMinutes: 59},
  {abbreviation: 'h', matchStrings: ['ho', 'hr'], title: 'hour', minutes: 60, maxMinutes: 479},
  {abbreviation: 'd', matchStrings: ['d'], title: 'day', minutes: 480, maxMinutes: 2399},
  {abbreviation: 'w', matchStrings: ['w'], title: 'week', minutes: 2400, maxMinutes: 10079},
  {abbreviation: 'mo', matchStrings: ['mo', 'mnth'], title: 'month', minutes: 10080, maxMinutes: 125279},
  {abbreviation: 'y', matchStrings: ['ye', 'yr'], title: 'year', minutes: 125280}
];

time.toLabel = (minutes, {format='short', precision=10}={}) => {
  minutes = Math.round(minutes) || 0; //HINT prevent invalid minutes values

  var scale = _$1.find(time.scales, scale => Math.abs(minutes) <= scale.maxMinutes) || _$1.last(time.scales);
  var number = Math.round(minutes / scale.minutes * 10) / 10;
  var suffix = format === 'short' ? scale.abbreviation : ` ${scale.title}s`;

  return (number === 0 || number === -1) ? '' : `${number}${suffix}`;
};

time.toMinutes = (label) => {
  label = label || '';

  var number = parseFloat(label) || 0;
  var unit = _$1.trim(label.replace(`${number}`, ''));

  var scale = _$1.find(time.scales, scale => {
    return unit === scale.abbreviation || _$1.some(scale.matchStrings, matchString => _$1.includes(unit, matchString));
  }) || time.scales[1]; //HINT default to hours if someone just types a number

  return number * scale.minutes;
};

var validation = {};

validation.emailIsValid = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

function __getHelper({shouldMemo}, {calculatePriceForInstance, getShippingCostFor}) {
  return function getCostsForOrder({productOrder, productInstances, includeDiscount=true}, dependencies) {
    var { activeOrg } = dependencies;

    var allProductsPrices = _$1.map(productInstances, (productInstance) => dinero({amount: calculatePriceForInstance({productInstance, productOrder}, dependencies)}));

    var orderSubTotal = _$1.reduce(allProductsPrices, (sum, n) => sum.add(n), dinero({amount: 0})).getAmount();

    var discount = 0;

    if (productOrder.orgId === 850) {
      var isSampleOrder = _$1.includes([6, 7], productOrder.productCategoryId);

      if (isSampleOrder) includeDiscount = false;
    }

    if (includeDiscount) {
      if (productOrder.discountMode === 'amount') {
        discount = _$1.get(productOrder, 'discountFlatAmount', 0);
      }
      else if (orderSubTotal) {
        discount = dinero({amount: orderSubTotal}).percentage(productOrder.discountPercentage).getAmount();
      }
      else {
        discount = 0;
      }
    }

    var state = _$1.upperCase(_$1.get(productOrder, 'deliveryAddress.state'));
    var shipping;
    var shippingIsTbd = true;

    if (productOrder.shippingMode === 'amount' && !_$1.isNil(productOrder.shippingFlatAmount)) {
      shipping = productOrder.shippingFlatAmount;
      shippingIsTbd = false;
    }
    else if (productOrder.shippingMode === 'rate' && !_$1.isNil(productOrder.shippingPercentage)) {
      shipping = (productOrder.shippingPercentage * 0.01) * orderSubTotal;
      shippingIsTbd = false;
    }
    else if (productOrder.orgId === 850 && productOrder.deliveryAddress.state !== '') {
      var orderPrice = orderSubTotal;
      var orderCreatedDate = productOrder.created;

      if (moment(orderCreatedDate).isSameOrAfter('2023-05-13')) { //HINT change to total after discount
        orderPrice = dinero({amount: orderSubTotal}).subtract(dinero({amount: discount})).getAmount();
      }

      shipping = getShippingCostFor({
        address: productOrder.deliveryAddress,
        orderPrice,
        productCategoryId: productOrder.productCategoryId,
        productInstances,
        orgId: productOrder.orgId,
        orderCreatedDate
      });
      shippingIsTbd = false;
    }

    shipping = shipping || 0; //HINT to account for null / undefined TBD potential

    var taxableSubtotalDinero = dinero({amount: orderSubTotal}).subtract(dinero({amount: discount}));

    var tax = 0;

    var disableSalesTax = productOrder.disableSalesTax || (productOrder.orgId === 850 && !_$1.includes(['CA', 'NY'], state));

    if (!disableSalesTax) {
      if (state !== 'CA') taxableSubtotalDinero = taxableSubtotalDinero.add(dinero({amount: shipping}));

      tax = taxableSubtotalDinero.multiply(_$1.get(productOrder, 'taxPercentage', 0)).getAmount();
    }

    var orderTotal = dinero({amount: orderSubTotal}).subtract(dinero({amount: discount})).add(dinero({amount: shipping})).add(dinero({amount: tax}));
    var orderTotalAmount = orderTotal.getAmount();

    var depositAmount = 0;
    var depositPercentage = 1;

    if (!productOrder.isStocked) {
      depositAmount = orderTotalAmount;
      depositPercentage = _$1.get(_$1.find(_$1.get(activeOrg, 'appData.designEngine.productOrderStatuses'), (status) => status.productOrderTotalPaymentPercentage), 'productOrderTotalPaymentPercentage') * 100 || 100;

      if (activeOrg) {
        if (depositPercentage) {
          depositAmount = orderTotal.percentage(depositPercentage).getAmount();
        }
      }
    }


    return {
      orderSubTotal,
      orderTotal: orderTotalAmount,
      discount,
      shipping,
      shippingIsTbd,
      tax,
      depositAmount,
      depositPercentage
    };
  }
}

// WARNING these costs should be calculated before discounts applied to orderprice
function __getHelper$1() {
  return function getShippingCostFor({address, orderPrice, productCategoryId = -1, productInstances = -1, orgId, orderCreatedDate}) {
    var isFromCalifornia = false;
    var isFromLosAngeles = false;
    var state = _$1.upperCase(_$1.get(address, 'state'));
    var city = _$1.upperCase(_$1.get(address, 'city'));
    var shipping = 0;

    if (state === 'CA') {
      isFromCalifornia = true;
      if (city === 'LOS ANGELES' || city === 'LA') isFromLosAngeles = true;
    }

    const calculateShipping = ({price}) => {
      if (moment(orderCreatedDate).isSameOrAfter('2023-05-13')) {
        if (price < 50001) shipping = isFromCalifornia ? 2500 : 3000;
        else if (price > 50000 && price < 100001) {
          if (isFromCalifornia) shipping = 3000;
          if (!isFromCalifornia) shipping = 3500;
        }
        else if (price > 100000 && price < 300000) {
          if (isFromCalifornia) shipping = 4500;
          if (!isFromCalifornia) shipping = 6000;
        }
        else {
          if (isFromLosAngeles === true ) shipping = 0.0225 * price;
          else if (isFromCalifornia === true) shipping = 0.0275 * price;
          else shipping = 0.0325 * price;
        }
      }
      else {
        if (price < 50001) shipping = isFromCalifornia ? 3000 : 4500;
        else if (price > 50000 && price < 100001) {
          if (isFromCalifornia) shipping = 4500;
          if (!isFromCalifornia) shipping = 6000;
        }
        else if (price > 100000 && price < 300000) {
          if (isFromCalifornia) shipping = 6000;
          if (!isFromCalifornia) shipping = 8500;
        }
        else {
          if (isFromLosAngeles === true ) shipping = 0.0225 * price;
          else if (isFromCalifornia === true) shipping = 0.0275 * price;
          else shipping = 0.0325 * price;
        }
      }
    };

    const calculateBackBoxShipping = ({price, quantity}) => {
      if (moment(orderCreatedDate).isSameOrAfter('2023-05-13')) {
        if (quantity <= 5) {
          if (isFromLosAngeles) shipping = 2000;
          else if (isFromCalifornia) shipping = 2500;
          else shipping = 3000;
        }
        else if (quantity > 5 && quantity <= 15) {
          if (isFromLosAngeles) shipping = 2500;
          else if (isFromCalifornia) shipping = 3000;
          else shipping = 3500;
        }
        else {
          if (price <= 50000) {
            shipping = isFromCalifornia ? 4500 : 3000;
          }
          else if (price <= 100000) {
            shipping = isFromCalifornia ? 6000 : 4500;
          }
          else if (price <= 300000) {
            shipping = isFromCalifornia ? 8500 : 6000;
          }
          else if (price > 300000) {
            shipping = (price * (isFromCalifornia ? 0.0375 : 0.025));
          }

          shipping += 5000;
        }
      }
      else {
        if (quantity < 10) {
          if (isFromLosAngeles) shipping = 2500;
          else if (isFromCalifornia) shipping = 3000;
          else shipping = 4500;
        }
        else {
          if (price <= 50000) {
            shipping = isFromCalifornia ? 4500 : 3000;
          }
          else if (price <= 100000) {
            shipping = isFromCalifornia ? 6000 : 4500;
          }
          else if (price <= 300000) {
            shipping = isFromCalifornia ? 8500 : 6000;
          }
          else if (price > 300000) {
            shipping = (price * (isFromCalifornia ? 0.0375 : 0.025));
          }

          shipping += 5000;
        }
      }
    };

    if (orgId === 850 && !_$1.isEmpty(productInstances)) {
      if (productCategoryId === 5) {
        calculateBackBoxShipping({city, state, price: orderPrice, quantity: _$1.sum(_$1.map(productInstances, 'quantity'))});
      }
      else {
        calculateShipping({city, state, price: orderPrice});
      }
    }

    return parseInt(shipping);
  };
}

function memo(fn, {argSpecs = [{destructure: true}]} = {}) {
  var cache = new ManyKeysMap();

  var memoizedFn = (...args) => {
    var value;
    var cacheKeyArray = _.flatMap(argSpecs, (argSpec, index) => argSpec.destructure ? Object.values(args[index]) : args[index]);

    if (cache.has(cacheKeyArray)) {
      value = cache.get(cacheKeyArray);
    }
    else {
      value = fn(...args);

      cache.set(cacheKeyArray, value);
    }

    return value;
  };

  memoizedFn.cache = cache;

  return memoizedFn;
}

function __getHelper$2({shouldMemo}, {priceFor, productPricingRuleVersionFor}) {
  function calculatePriceForInstance({productInstance, productOrder}, dependencies) {
    const {productsById, productPricingRulesById} = dependencies;

    const {expressions} = productPricingRuleVersionFor({productInstance, productOrder}, {productsById, productPricingRulesById});
    const product = productsById[productInstance.productId];

    const price = priceFor(expressions, {productInstance, shouldRoundPrice: _$1.get(product, 'shouldRoundPrice', false)}, {...dependencies, productOrder});

    return price;
  }

  if (shouldMemo) {
    return memo(calculatePriceForInstance, {argSpecs: [{destructure: true}]}); //WARNING intentionally not memoizing second argument because no values should matter there for memoization and productInstancesById is being passed for sortedPRoductPropertiesForProductInstance
  }
  else {
    return calculatePriceForInstance;
  }
}

function __getHelper$3({shouldMemo}) {
  function defaultProductOptionFor({productPropertyId}, dependencies) {
    var productOptions = dependencies.productOptionsById;

    productOptions = _.filter(productOptions, productOption => productOption.productPropertyId === productPropertyId && !productOption.archived);
    productOptions = _.sortBy(productOptions, 'rank');

    return productOptions[0];
  }

  if (shouldMemo) {
    return memo(defaultProductOptionFor, {argSpecs: [{destructure: true}, {destructure: true}]});
  }
  else {
    return defaultProductOptionFor;
  }
}

function __getHelper$4({shouldMemo}, {productRulesFor, evaluateProductRule}) {
  function productOptionsForProperty({productProperty, productInstance}, {productRulesById, productOptionsById, productsById}) {
    var product = productsById[productInstance.productId];

    var disabledProductOptionIds = _.get(product, `associations.productProperties.${productProperty.id}.disabledProductOptionIds`, []);

    return _.chain(productOptionsById)
      .reject('archived')
      .filter({productPropertyId: productProperty.id})
      .filter(productOption => {
        var isValidOption = true;

        if (_.includes(disabledProductOptionIds, productOption.id)) {
          isValidOption = false;
        }
        else {
          var productRules = productRulesFor({resourceKey: 'productOption', resourceId: productOption.id}, productRulesById, {productInstance, productOptionsById, productPropertyId: productOption.productPropertyId, product});

          if (productRules.length) {
            var productRuleEvaluationResults = _.map(productRules, productRule => evaluateProductRule({productRule, resourceKey: 'productOption'}, {productInstance}, {productOptionsById, productOption, productsById}));

            if (!_.every(productRuleEvaluationResults)) {
              isValidOption = false;
            }
          }
        }

        return isValidOption;
      })
      .sortBy('rank')
      .value();
  }

  return shouldMemo ? memo(productOptionsForProperty, {argSpecs: [{destructure: true}, {destructure: true}]}) : productOptionsForProperty;
}

function __getHelper$5({shouldMemo}, {
  productPricingRuleVersionFor,
  // defaultProductOptionFor,
  productPropertyClassifierNumberFor,
  sortedProductPropertiesForProductInstance,
  getArrayQuantityForArrayProductProperty,
  productOptionForInstanceProperty,
  meljac: {getUsedEngravingCount}
}) {
  return function priceFor(expressions, {productInstance, shouldRoundPrice = false, roundToValue = 100, shouldApplyQuantity = true}, dependencies) {
    var price = 0;
    var {productOptionsById, productPricingRulesById, productOptionClassifiersById, productsById, productPropertiesById, productRulesById, productInstancesById, productOrder} = dependencies;

    var productPropertiesForProductInstance = sortedProductPropertiesForProductInstance({productInstance}, {productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById});

    var numberFor = ({type, props, expressions}) => {
      var number;

      if (type === 'fixed') {
        number = props.value;
      }
      else if (type === 'group') {
        number = priceFor(expressions, {productInstance, shouldApplyQuantity: false}, dependencies);
      }
      else if (_$1.includes(['productPropertyValue', 'productPropertyPrice', 'productPropertyClassifierNumber'], type)) {
        //HINT check if productProperty is valid for productInstance based on rules
        var productPropertyIsValid = _$1.filter(productPropertiesForProductInstance, {id: _$1.get(props, 'productPropertyId')}).length > 0;

        if (productPropertyIsValid) {
          if (type === 'productPropertyValue') {
            number = _$1.get(productInstance, `properties.${props.productPropertyId}.value`, 0);
          }
          else if (_$1.includes(['productPropertyPrice', 'productPropertyClassifierNumber'], type)) {
            var productProperty = _$1.get(productPropertiesById, props.productPropertyId);
            var defaultOption = productOptionForInstanceProperty({productInstance, productProperty}, {productOptionsById, productRulesById, productsById});

            // var defaultOption = defaultProductOptionFor({productPropertyId: props.productPropertyId}, {productOptionsById});
            var selectedOptionId = _$1.get(productInstance, `properties.${props.productPropertyId}.optionId`, defaultOption.id);

            if (type === 'productPropertyPrice') {
              var productOptionProductPricingRuleId = _$1.get(_$1.find(productOptionsById, {id: selectedOptionId}), 'productPricingRuleId');

              if (productOptionProductPricingRuleId) {
                var productOptionProductPricingRule = _$1.get(productPricingRulesById, productOptionProductPricingRuleId);
                var {expressions} = productPricingRuleVersionFor({productInstance, productOrder, productPricingRule: productOptionProductPricingRule});

                number = priceFor(expressions, {productInstance, shouldApplyQuantity: false}, dependencies);
              }
            }
            else if (type === 'productPropertyClassifierNumber') {
              var {productOptionClassifierGroupId, productOptionClassifierNumberIndex} = props;

              var productPropertyClassifierNumber = productPropertyClassifierNumberFor({productOptionClassifierGroupId, productOptionClassifierNumberIndex, selectedOptionId, productOptionsById, productOptionClassifiersById, productOrder});

              if (_$1.isNumber(productPropertyClassifierNumber)) number = productPropertyClassifierNumber;
            }
          }
        }
      }
      else if (type === 'arrayLength') {
        var productProperty = _$1.get(productPropertiesById, props.productPropertyId);
        var arrayLength = getArrayQuantityForArrayProductProperty({productInstance, productProperty}, {productOptionsById, productRulesById, productsById, productPropertiesById});

        if (productInstance.orgId === 850) { //HINT MNA custom pricing
          if (props.customLogicKey === 'engravings') {
            var {usedEngravingCount, usedTextEngravingsCount, usedSymbolEngravingsCount} = getUsedEngravingCount({productInstance, productPropertyId: props.productPropertyId, productOptionsById, productsById, productRulesById, productPropertiesById, productInstancesById, arrayLength, productProperty});

            if (usedTextEngravingsCount > 0) {
              number = props.firstEngravingPrice;

              number += (usedEngravingCount - 1) * props.subsequentEngravingPrice;
            }
            if (usedSymbolEngravingsCount > 0) {
              number = props.symbolEngravings.firstEngravingPrice;

              number += (usedEngravingCount - 1) * props.symbolEngravings.subsequentEngravingPrice;
            }
          }
        }
        // else {
        //   // var propertyData = _.get(productInstance, `properties.${props.propertiesPath}`);

        //   // number = _.compact(_.flattenDeep(propertyData)).length;
        // }
      }
      else if (type === 'propertyValue') {
        number = _$1.get(productInstance, `properties.${props.propertiesPath}`);
      }

      return number;
    };

    if (productInstance.productId === -1) {
      price = _$1.get(productInstance, 'customPriceInCents', 0);
    }
    else {
      _$1.forEach(expressions, ({type, props, operator, expressions, condition, description = ''}) => {
        var shouldEvaluate = true;

        if (type === 'group' && condition) {
          var conditionResults = _$1.map(condition.conditions, ({operator, expected, actual}) => {
            var actualNumber = numberFor({type: actual.type, props: actual.props});
            var expectedNumber = expected.value;

            if (operator === '===') {
              return expectedNumber === actualNumber;
            }
            else if (operator === '!==') {
              return expectedNumber !== actualNumber;
            }
            else if (operator === '>=') {
              return actualNumber >= expectedNumber;
            }
          });

          if (condition.operator === 'and' && _$1.includes(conditionResults, false)) {
            shouldEvaluate = false;
          }
          else if (condition.operator === 'or' && _$1.every(conditionResults, false)) {
            shouldEvaluate = false;
          }
        }

        if (shouldEvaluate) {
          var number = numberFor({type, props, expressions});

          if (_$1.isNumber(number)) {
            if (operator === '+') {
              price += number;
            }
            else if (operator === '-') {
              price -= number;
            }
            else if (operator === '*') {
              price *= number;
            }
          }
        }
      });
    }

    if (shouldRoundPrice) price = Math.round(price / roundToValue) * roundToValue;

    if (shouldApplyQuantity) price *= productInstance.quantity;

    return price;
  }
}

function __getHelper$6() {
  return function productPricingRuleVersionFor({productInstance, productPricingRule, productOrder}, dependencies) {
    if (productInstance.productId === -1) return {expressions: []};
    else {
      if (!productPricingRule) {
        var {productsById, productPricingRulesById} = dependencies;

        var product = productsById[productInstance.productId];

        var productPricingRule = _$1.get(productPricingRulesById, product.productPricingRuleId);

        if (productPricingRule === undefined) return {expressions: []};
      }

      var effectivePricingDate = moment().format('YYYY-MM-DD HH:mm:ss');

      if (productOrder) effectivePricingDate = productOrder.effectivePricingDate || productOrder.created;

      var productPricingRuleVersionsCreatedBeforeEffectivePricingDate = _$1.filter(productPricingRule.versions, (version) => {
        return moment(version.created).isSameOrBefore(effectivePricingDate);
      });

      return _$1.last(_$1.sortBy(_$1.map(productPricingRuleVersionsCreatedBeforeEffectivePricingDate, (version) => {
        return {...version, createdUnixTimestamp: moment(version.created).unix()}
      }), ['createdUnixTimestamp']));
    }
  };
}

function __getHelper$7() {
  return function productPropertyClassifierNumberFor(dependencies) {
    var {productOptionClassifierGroupId, productOptionClassifierNumberIndex, selectedOptionId, productOptionsById, productOptionClassifiersById, productOrder} = dependencies;

    var productPropertyClassifierNumber;

    var {classifications} = productOptionsById[selectedOptionId];

    var productOptionClassifierId = classifications[productOptionClassifierGroupId];

    var productOptionClassifier = productOptionClassifiersById[productOptionClassifierId];

    var effectivePricingDate = moment().format('YYYY-MM-DD HH:mm:ss');

    if (productOrder) effectivePricingDate = productOrder.effectivePricingDate || productOrder.created;

    if (productOptionClassifier) {
      var productPropertyClassifier = _$1.chain(productOptionClassifier.numbers)
      .filter(numbers => moment(numbers.date).isSameOrBefore(effectivePricingDate))
      .orderBy(['date'], ['desc'])
      .first()
      .value();

      productPropertyClassifierNumber = productPropertyClassifier.numbers[productOptionClassifierNumberIndex];
    }


    return productPropertyClassifierNumber;
  }
}

function __getHelper$8({shouldMemo}, {productRulesFor, evaluateProductRule}) {
  return function sortedProductPropertiesForProductInstance({productInstance}, dependencies) {
    var {productsById, productPropertiesById, productRulesById, productOptionsById, productInstancesById} = dependencies;

    var product = _.get(productsById, productInstance.productId);
    var productPropertyAssociations = _.get(product, 'associations.productProperties');
    var productPropertiesForProductInstance = _.chain(productPropertyAssociations)
      .map((_value, productPropertyId) => productPropertiesById[productPropertyId])
      .filter((productProperty) => !!productProperty)
      .filter((productProperty) => {
        var isValidProperty = true;

        var productRules = productRulesFor({resourceKey: 'productProperty', resourceId: productProperty.id}, productRulesById, {productInstance, productOptionsById, product});

        if (productRules.length) {
          var productRuleEvaluationResults = _.map(productRules, productRule => evaluateProductRule({productRule, resourceKey: 'productProperty'}, {productInstance}, {productOptionsById, productsById}));

          if (!_.every(productRuleEvaluationResults)) isValidProperty = false;
        }

        var productInstances = _.filter(productInstancesById, {productOrderId: productInstance.orderId});
        var isInUseInOrder = _.some(productInstances, ({properties}) => !!properties[productProperty.id]);

        if (productProperty.archived && isInUseInOrder) isValidProperty = false;

        return isValidProperty;
      })
      .sortBy('rank')
      .value();

    return productPropertiesForProductInstance;
  }
}

//helpful for getting, for example, the option a user has selected for the edge type property - i.e. {id: 0, title: 'Beveled'}
function __getHelper$9({shouldMemo}, {productOptionsForProperty}) {
  return function productOptionForInstanceProperty({productInstance, productProperty}, {productRulesById, productOptionsById, productsById}) {
    var optionId = _.get(productInstance, `properties.${productProperty.id}.optionId`);
    var productOptions = productOptionsForProperty({productProperty, productInstance}, {productRulesById, productOptionsById, productsById}); //get candidate options - filters out an invalid/irrelevant options and sorts
    var productOption = _.find(productOptions, {id: optionId}) || productOptions[0]; //find the specified option, otherwise default to the first one

    return productOption;
  }
}

function __getHelper$a({shouldMemo}, {sortedProductPropertiesForProductInstance, productOptionForInstanceProperty}) {
  function getProductInstanceWithData({productInstance}, dependencies) {
    var sortedProductProperties = sortedProductPropertiesForProductInstance({productInstance}, dependencies);

    var sortedPropertiesData = _.map(sortedProductProperties, (productProperty) => ({
      productProperty,
      selectedProductOption: productOptionForInstanceProperty({productInstance, productProperty}, dependencies)
      //TODO types of values other than single select
    }));

    var propertiesDataById = _.keyBy(sortedPropertiesData, ({productProperty}) => productProperty.id);

    var product = dependencies.productsById[productInstance.productId];

    var productTitle = productInstance.productId === -1 ? 'Custom Product' : _.get(product, 'title', '');

    return {
      ...productInstance,
      formattedTitle: (productInstance.title ? productInstance.title + ' - ' : '') + productTitle,
      product,
      propertiesDataById,
      sortedPropertiesData
    };
  }

  return shouldMemo ? memo(getProductInstanceWithData, {argSpecs: [{destructure: true}, {destructure: true}]}) : getProductInstanceWithData;
}

function __getHelper$b({shouldMemo}, {getProductInstanceWithData}) {
  return function getProductInstancesWithData({productInstances}, dependencies) {
    return _.map(productInstances, productInstance => getProductInstanceWithData({productInstance}, dependencies));
  }
}

function __getHelper$c() {
  return function productRulesFor({resourceKey, resourceId}, productRulesById, dependencies = {}) {
    var {productPropertyId, product, productOptionsById} = dependencies;

    return _.filter(productRulesById, productRule => {
      var shouldInclude = true;

      if (product.id === -1) {
        shouldInclude = false;
      }
      else {
        shouldInclude = shouldInclude && productRule.toResourceKey === resourceKey;

        if (resourceKey === 'productProperty') {
          shouldInclude = shouldInclude && _.includes(productRule.toIds, resourceId);
        }

        if (productRule.toResourceKey === 'productOption') {
          shouldInclude = shouldInclude && productRule.toProductPropertyId === productPropertyId;

          if (productRule.fromResourceKey === 'productOption') {
            //HINT product must have productProperty for productOption in associations
            var fromProductPropertyId = productOptionsById[productRule.fromIds[0]].productPropertyId;

            shouldInclude = shouldInclude && product.associations.productProperties && !!product.associations.productProperties[fromProductPropertyId];
          }
        }
      }

      return shouldInclude;
    });
  }
}

function __getHelper$d({shouldMemo}, {defaultProductOptionFor}) {
  function evaluateProductRule({productRule, resourceKey}, {productInstance}, dependencies = {}) {
    var {productOptionsById, productOption, productsById} = dependencies;

    var isValid = true;

    if (productRule.fromResourceKey === 'productOption') {
      var fromProductPropertyId = productOptionsById[_$1.first(productRule.fromIds)].productPropertyId;

      var defaultOption = defaultProductOptionFor({productPropertyId: fromProductPropertyId}, {productOptionsById});
      var selectedOptionId = _$1.get(productInstance, `properties.${fromProductPropertyId}.optionId`, defaultOption.id);

      if (resourceKey === 'productOption') {
        if (_$1.includes(productRule.fromIds, selectedOptionId)) {
          if (productRule.type === 'compatible' && !_$1.includes(productRule.toIds, productOption.id)) {
            isValid = false;
          }
          else if (productRule.type === 'incompatible' && _$1.includes(productRule.toIds, productOption.id)) {
            isValid = false;
          }
        }
      }
      else if (resourceKey === 'productProperty') {
        var product = productsById[productInstance.productId];
        var productPropertyIdsForProduct = _$1.map(_$1.get(product, 'associations.productProperties'), (_value, key) => parseInt(key));

        if (_$1.includes(productPropertyIdsForProduct, fromProductPropertyId)) {
          if (productRule.type === 'compatible' && selectedOptionId !== _$1.first(productRule.fromIds)) {
            isValid = false;
          }
          else if (productRule.type === 'incompatible' && selectedOptionId === _$1.first(productRule.fromIds)) {
            isValid = false;
          }
        }
      }
    }

    return isValid;
  }

  return evaluateProductRule;
}

function __getHelper$e({shouldMemo}, {}) {
  return function getActiveProductOrderStatus({productOrderStatuses, statusData}) {
    var productOrderStatusesMap = _.chain(productOrderStatuses).mapValues((status, index) => ({...status, index: Number(index)})).mapKeys((status) => status.key).mapValues((status) => status.index).value();

    var activeProductOrderStatus = _.get(productOrderStatuses, _.max(_.map(statusData, (status, key) => productOrderStatusesMap[key])) + 1);

    return activeProductOrderStatus;
  }
}

function __getHelper$f() {
  return function getLastCompletedProductOrderStatus({productOrderStatuses, statusData}) {
    var productOrderStatusesMap = _.chain(productOrderStatuses).mapValues((status, index) => ({...status, index: Number(index)})).mapKeys((status) => status.key).mapValues((status) => status.index).value();

    var lastCompletedProductOrderStatus = _.get(productOrderStatuses, _.max(_.map(statusData, (status, key) => productOrderStatusesMap[key])));

    return lastCompletedProductOrderStatus;
  }
}

function __getHelper$g() {
  return function toggleProductOrderStatusComplete({statusKey, statusData, productOrderStatuses}) {
    var resultStatusData = {};
    var resultStatusDataComplete = false;

    _.forEach(productOrderStatuses, status => {
      if (!resultStatusDataComplete) {
        var statusKeyIsSet = statusData[status.key];

        if (!statusKeyIsSet || statusKey === status.key) {
          resultStatusData[status.key] = {date: moment().utc().format('YYYY-MM-DD HH:mm:ss')};
        }
        else {
          resultStatusData[status.key] = statusData[status.key];
        }

        if (statusKey === status.key) resultStatusDataComplete = true;
      }
    });

    return resultStatusData;
  }
}

function __getHelper$h() {
  return function getPaymentAmountForOrder({productOrder, orderCosts, isCardPayment = false, payInFull = false, shippedWithoutFinalPayment = false}, {productOrderStatuses, activeProductOrderStatus, activeOrg}) {
    var paymentAmount = 0;

    var shouldApplyUpcharge = false;
    var upchargePercentage = 29; //2.9% upcharge calculation of amount for this percent will be paymentAmount * (29 / 1000)
    var upchargeAmount = 0;
    var runningPaymentPercentage = 0;

    var activeProductOrderStatusType = _.get(activeProductOrderStatus, 'type');
    var activeProductOrderStatusTypeIndex = _.findIndex(productOrderStatuses, {key: _.get(activeProductOrderStatus, 'key')});

    if (shippedWithoutFinalPayment) { //HINT override usual behavior
      activeProductOrderStatusType = 'payment';
      activeProductOrderStatusTypeIndex = _.findIndex(productOrderStatuses, {key: 'finalPaymentReceived'});
    }

    if (activeProductOrderStatusType === 'payment') {
      if (payInFull || productOrder.isStocked) {
        runningPaymentPercentage = 1;
      }
      else {
        for (let index = 0; index <= activeProductOrderStatusTypeIndex; index++) {
          var productOrderStatus = productOrderStatuses[index];

          if (productOrderStatus.type === 'payment') {
            runningPaymentPercentage += _.get(productOrderStatus, 'productOrderTotalPaymentPercentage');
          }
        }
      }
    }
    else {
      var alreadyAppliedDepositPercentage = false;

      for (let index = 0; index < _.size(productOrderStatuses); index++) {
        var productOrderStatus = productOrderStatuses[index];

        if(index < activeProductOrderStatusTypeIndex) {
          if (productOrderStatus.type === 'payment') {
            runningPaymentPercentage += _.get(productOrderStatus, 'productOrderTotalPaymentPercentage');
          }
        }
        else if(index > activeProductOrderStatusTypeIndex) {
          if(productOrderStatus.type === 'payment') {
            if (!alreadyAppliedDepositPercentage) {
              runningPaymentPercentage += _.get(productOrderStatus, 'productOrderTotalPaymentPercentage');
              alreadyAppliedDepositPercentage = true;
            }
          }
        }
      }
    }

    paymentAmount = dinero({amount: orderCosts.orderTotal}).percentage(runningPaymentPercentage * 100).subtract(dinero({amount: productOrder.amountPaidInCents})).getAmount();

    shouldApplyUpcharge = activeOrg.id === 850 && dinero({amount: paymentAmount}).greaterThan(dinero({amount: 300000})) && isCardPayment;

    if (shouldApplyUpcharge) {
      upchargeAmount = dinero({amount: paymentAmount}).multiply(upchargePercentage).divide(1000).getAmount();
    }

  return {paymentAmount, shouldApplyUpcharge, upchargePercentage, upchargeAmount};
  }
}

function __getHelper$i() {
  return function getFilteredProductOrderStatuses({productOrderStatuses, userRole}) {
    var filters = {
      ...(!_.includes(['owner', 'member'], userRole) ? { isVisibleToGuest: 1} : {}),
      };

    return _.filter(productOrderStatuses, filters);
  }
}

function __getHelper$j() {
  return function getProductOrderStatusesFor({productOrder, productOrderStatuses}){
    var filters = {
      ...(_.size(productOrder) && productOrder.isStocked ? {isEnabledForStockedOrder: 1} : {}),
    };

    return _.filter(productOrderStatuses, filters);
  }
}

function __getHelper$k() {
  return function getDefaultDescriptionForProductOrderStatus({productOrder, productOrderStatus, org}) {
    return `Your order "${productOrder.title}" has been updated. Its status is now: ${productOrderStatus.title}`;
  }
}

function __getHelper$l() {
  return function getNameFor({productOrder, orgId, textToTransform: text, pluralize = false}) {
    var name = 'Order';

    text = text || 'Order';

    if (orgId === 850) {
      name = 'Quote';
      //if (_.get(productOrder, 'status') !== productOrderStatuses[4].value) {
    }
    if (orgId === 1798) {
      name = 'Project';
    }

    if (name !== 'Order') {
      text = _.replace(text, new RegExp('order', 'g'), name.toLowerCase());
      text = _.replace(text, new RegExp('Order', 'g'), name);
    }

    return text;
  }
}

function __getHelper$m({shouldMemo}, {productOptionForInstanceProperty}) {
  return function getArrayQuantityForArrayProductProperty({productInstance, productProperty}, {productOptionsById, productRulesById, productsById, productPropertiesById}) {
    var arrayQuantity = 0;
    var {isArray, arrayQuantity: arrayQuantityData} = productProperty;

    if (productProperty.type === 'text' && isArray && arrayQuantityData) {
      //HINT arrayQuantity is an array of props that should pull a quantity to render inputs, only one set of props should apply to each product
      var inputQuantities = _.map(arrayQuantityData, (props) => {
        var productPropertySelected = _.get(productPropertiesById, _.get(props, 'productPropertyId'));
        var product = _.get(productsById, productInstance.productId);

        var productUsesSelectedProperty = _.get(product, 'associations.productProperties')[productPropertySelected.id];

        if (productProperty && productUsesSelectedProperty && _.get(productProperty, 'deleted') !== 1) {
          return _.get(productOptionForInstanceProperty({
            productInstance,
            productProperty: productPropertySelected,
          }, {productOptionsById, productRulesById, productsById}), 'data.mechanismCount', 0);
        }
      });

      arrayQuantity = _.max(inputQuantities); //HINT should return undefined for properties that do not apply
    }

    return arrayQuantity;
  }
}

function __getHelper$n({shouldMemo}, {meljac: {getMechanismSpacings, getKeypadData, getMechanismLayoutData}}) {
  return function getEngravingHeightLimit({activeProductInstanceWithData: productInstanceWithData, inputArrayIndex}) {
    var engravingHeightIsOverLimit = false;
    var productId = productInstanceWithData.productId;

    var {plateSizePropertyId, backBoxType, backBoxId, mechanismLayoutPropertyId, collection} = getKeypadData({productId, productInstanceWithData});
    var {layoutStringArray, totalRows, totalColumns} = getMechanismLayoutData({mechanismLayoutPropertyId, productInstanceWithData});

    var plateSizeData = (_$1.get(productInstanceWithData, `propertiesDataById.${plateSizePropertyId}` + '.selectedProductOption.data'));

    var {ySpacing} = getMechanismSpacings({width: plateSizeData.width, height: plateSizeData.height, backBoxId, backBoxType, totalRows, totalColumns, productId});

    var engravingFontSize = _$1.get(productInstanceWithData, 'propertiesDataById.38.selectedProductOption.data.fontSize');
    var position = _$1.get(productInstanceWithData, 'propertiesDataById.108.selectedProductOption.id') === 624 ? 'below' : 'above';
    var engravingBoxHeightBasedOnPositionArray = [];
    var mechanismEngravingSpacing = 3;

    _$1.forEach(layoutStringArray, (row, rowIndex) => {
      _$1.forEach(row, (column) => {
        var mechanismRadius = 6;

        if (collection === 'damier' && column === 'S') mechanismRadius = mechanismRadius + 1.2;
        if (collection === 'ellipse') mechanismRadius = mechanismRadius + 1.25;
        if (collection === 'solaris') mechanismRadius = mechanismRadius + 1;

        var spaceBetweenMechanismAndPlatePerimeter = (plateSizeData.height / 2) - ((ySpacing * (totalRows - 1)) / 2) - mechanismRadius;
        var spaceBetweenMechanisms = ySpacing - (mechanismRadius * 2);

        if (rowIndex === 0 && position === 'above' || rowIndex === layoutStringArray.length - 1 && position === 'below') {
          var engravableSpace = spaceBetweenMechanismAndPlatePerimeter - (mechanismEngravingSpacing * 2);
          engravingBoxHeightBasedOnPositionArray.push(engravableSpace);
        }
        else {
          var engravableSpace = spaceBetweenMechanisms - (mechanismEngravingSpacing * 2);
          engravingBoxHeightBasedOnPositionArray.push(engravableSpace);
        }
      });
    });

    if ((engravingFontSize * 2) + 2 > engravingBoxHeightBasedOnPositionArray[inputArrayIndex]) engravingHeightIsOverLimit = true;

    return engravingHeightIsOverLimit;
  }
}

function __getHelper$o({shouldMemo}) {
  return function getCleanedEngravings({properties, propertiesDataById}) {

    var engravingsPropertyData = _$1.get(properties, '27', {});

    var engravings = _$1.cloneDeep(_$1.get(engravingsPropertyData, 'engravings'));

    if (!engravings) {
      var engravingLocation = _$1.get(propertiesDataById, '108.selectedProductOption.id') === 623 ? 'above' : 'below';
      engravings = {};

      _$1.forEach(_$1.get(engravingsPropertyData, 'text'), (engravingTextArray, mechanismIndex) => {
        if (engravingLocation === 'above') {
          _$1.reverse(engravingTextArray);
        }
        engravings[mechanismIndex] = {
          [engravingLocation]: _$1.map(engravingTextArray, engravingText => ({text: engravingText}))
        };
      });
    }

    return engravings;
  }
}

function __getHelper$p() {
  return function getKeypadData({productId, productInstanceWithData}) {
    var plateSizePropertyId;
    var backBoxType;
    var backBoxId;
    var mechanismLayoutPropertyId;
    var collection;

    if (productId === 1) {
      plateSizePropertyId = 181;
      backBoxType = _.get(productInstanceWithData, 'propertiesDataById.84.selectedProductOption.data.type');
      backBoxId = _.get(productInstanceWithData, 'propertiesDataById.84.selectedProductOption.id');
      mechanismLayoutPropertyId = 83;
      collection = 'crestnet';
    }

    if (productId === 2) {
      plateSizePropertyId = 6;
      backBoxType = _.get(productInstanceWithData, 'propertiesDataById.29.selectedProductOption.data.type');
      backBoxId = _.get(productInstanceWithData, 'propertiesDataById.29.selectedProductOption.id');
      mechanismLayoutPropertyId = 4;
      collection = 'classique';
    }

    if (productId === 5) {
      plateSizePropertyId = 57;
      backBoxType = _.get(productInstanceWithData, 'propertiesDataById.29.selectedProductOption.data.type');
      backBoxId = _.get(productInstanceWithData, 'propertiesDataById.29.selectedProductOption.id');
      mechanismLayoutPropertyId = 56;
      collection = 'damier';
    }

    if (productId === 10) {
      plateSizePropertyId = 35;
      backBoxType = _.get(productInstanceWithData, 'propertiesDataById.92.selectedProductOption.data.type');
      backBoxId = _.get(productInstanceWithData, 'propertiesDataById.92.selectedProductOption.id');
      mechanismLayoutPropertyId = 114;
      collection = 'solaris';
    }

    if (productId === 11) {
      plateSizePropertyId = 105;
      backBoxType = _.get(productInstanceWithData, 'propertiesDataById.29.selectedProductOption.data.type');
      backBoxId = _.get(productInstanceWithData, 'propertiesDataById.29.selectedProductOption.id');
      mechanismLayoutPropertyId = 4;
      collection = 'ellipse';
    }

    return {plateSizePropertyId, backBoxType, backBoxId, mechanismLayoutPropertyId, collection};
  }
}

function __getHelper$q() {
  return function getMechanismLayoutData({productInstanceWithData, mechanismLayoutPropertyId}) {
    var mechanismLayoutPropertyData = _.get(productInstanceWithData, `propertiesDataById.${mechanismLayoutPropertyId}`);
    var layoutStringData = _.get(mechanismLayoutPropertyData, 'selectedProductOption.data');
    var layoutStringArray = _.split(_.replace(_.get(layoutStringData, 'layoutString', ''), ' ', ''), '-'); //HINT ['BBB', 'BBB'] => 2 rows, 3 columns

    var totalRows = layoutStringArray.length;
    var totalColumns = _.max(_.map(layoutStringArray, 'length'));

    return {layoutStringArray, totalRows, totalColumns};
  }
}

function __getHelper$r() {
  return function getMechanismSpacings({width, height, backBoxId, backBoxType, totalRows, totalColumns, productId, mechanismTypeData}) {
    var spacingRules;
    var hasToggle = false;
    var hasButton = false;

    if (_$1.get(mechanismTypeData, 'id') === 129) hasButton = true;
    if (_$1.get(mechanismTypeData, 'id') === 130) hasToggle = true;
    if (_$1.get(mechanismTypeData, 'id') === 131) {
      hasButton = true;
      hasToggle = true;
    }
    if (productId === 5 || productId === 1) {
      hasButton = true;
      hasToggle = false;
    }

    if (productId === 1) { //crestnet
      spacingRules = [
        {axis: 'y', height: 117, rows: 3, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 24},
        {axis: 'y', height: 117, rows: 3, hasButton: true, hasToggle: false, backBoxType: 'MUD RING', spacing: 24},
        {axis: 'x', width: 82, columns: 2, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 32},
        {axis: 'x', width: 82, columns: 2, hasButton: true, hasToggle: false, backBoxType: 'MUD RING', spacing: 32}
      ];
    }

    if (productId === 2 || productId === 5) { //classique and damier
      spacingRules = [
      {axis: 'y', height: 82, rows: 2, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 30},
      {axis: 'y', height: 82, rows: 2, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 30},
      {axis: 'y', height: 82, rows: 2, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 30},
      {axis: 'y', height: 82, rows: 2, hasButton: true, hasToggle: false, backBoxType: 'MUD RING', spacing: 28},

      {axis: 'y', height: 117, rows: 2, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 36},
      {axis: 'y', height: 117, rows: 2, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 36},
      {axis: 'y', height: 117, rows: 2, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 36},

      {axis: 'y', height: 117, rows: 2, hasButton: true, hasToggle: false, backBoxType: 'MUD RING', spacing: 36},
      {axis: 'y', height: 117, rows: 2, hasButton: false, hasToggle: true, backBoxType: 'MUD RING', spacing: 36},
      {axis: 'y', height: 117, rows: 2, hasButton: true, hasToggle: true, backBoxType: 'MUD RING', spacing: 36},

      // HINT backboxId 145 is '1 gang ring (u4)'
      // {axis: 'y', height: 117, rows: 2, hasButton: true, hasToggle: false, backBoxId: 145, spacing: 32},
      // {axis: 'y', height: 117, rows: 2, hasButton: false, hasToggle: true, backBoxId: 145, spacing: 32},
      // {axis: 'y', height: 117, rows: 2, hasButton: true, hasToggle: true, backBoxId: 145, spacing: 32},

      {axis: 'y', height: 117, rows: 3, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 28},
      {axis: 'y', height: 117, rows: 3, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 30},

      {axis: 'y', height: 117, rows: 3, hasButton: true, hasToggle: false, backBoxType: 'MUD RING', spacing: 28},
      {axis: 'y', height: 117, rows: 3, hasButton: true, hasToggle: true, backBoxType: 'MUD RING', spacing: 30},

      // {axis: 'y', height: 117, rows: 3, hasButton: true, hasToggle: false, backBoxId: 145, spacing: 24},
      {axis: 'y', height: 117, rows: 3, hasButton: true, hasToggle: true, backBoxId: 145, spacing: 30},

      {axis: 'y', height: 144, rows: 2, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 46},
      {axis: 'y', height: 144, rows: 2, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 46},
      {axis: 'y', height: 144, rows: 2, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 46},

      {axis: 'y', height: 144, rows: 3, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 36},
      {axis: 'y', height: 144, rows: 3, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 36},
      {axis: 'y', height: 144, rows: 3, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 36},

      {axis: 'y', height: 144, rows: 4, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 28},
      {axis: 'y', height: 144, rows: 4, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 28},
      {axis: 'y', height: 144, rows: 4, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 30},
      {axis: 'y', height: 144, rows: 4, hasButton: true, hasToggle: true, backBoxId: 770, spacing: 30},
      {axis: 'y', height: 144, rows: 4, hasButton: false, hasToggle: true, backBoxId: 770, spacing: 30},

      {axis: 'y', height: 91, rows: 2, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 28},
      {axis: 'y', height: 91, rows: 2, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 32},
      {axis: 'y', height: 91, rows: 2, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 28},

      {axis: 'y', height: 133, rows: 2, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 42},
      {axis: 'y', height: 133, rows: 2, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 42},
      {axis: 'y', height: 133, rows: 2, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 42},

      {axis: 'y', height: 133, rows: 3, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 32},
      {axis: 'y', height: 133, rows: 3, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 32},
      {axis: 'y', height: 133, rows: 3, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 32},

      {axis: 'y', height: 133, rows: 4, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 22},
      {axis: 'y', height: 133, rows: 4, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 32},

      {axis: 'y', height: 175, rows: 2, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 58},
      {axis: 'y', height: 175, rows: 2, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 58},
      {axis: 'y', height: 175, rows: 2, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 58},

      {axis: 'y', height: 175, rows: 3, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 44},
      {axis: 'y', height: 175, rows: 3, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 44},
      {axis: 'y', height: 175, rows: 3, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 44},

      {axis: 'y', height: 175, rows: 4, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 35},
      {axis: 'y', height: 175, rows: 4, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 35},
      {axis: 'y', height: 175, rows: 4, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 35},

      {axis: 'y', height: 175, rows: 5, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 29},
      {axis: 'y', height: 175, rows: 5, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 29},

      {axis: 'y', height: 207, rows: 3, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 59},
      {axis: 'y', height: 207, rows: 3, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 59},
      {axis: 'y', height: 207, rows: 3, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 59},

      {axis: 'y', height: 207, rows: 4, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 40},
      {axis: 'y', height: 207, rows: 4, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 40},
      {axis: 'y', height: 207, rows: 4, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 40},

      {axis: 'y', height: 207, rows: 5, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 32},
      {axis: 'y', height: 207, rows: 5, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 32},
      {axis: 'y', height: 207, rows: 5, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 32},

      {axis: 'y', height: 207, rows: 6, hasButton: true, hasToggle: false, backBoxType: 'MELUS', spacing: 28},
      {axis: 'y', height: 207, rows: 6, hasButton: false, hasToggle: true, backBoxType: 'MELUS', spacing: 32},
      {axis: 'y', height: 207, rows: 6, hasButton: true, hasToggle: true, backBoxType: 'MELUS', spacing: 32},

      {axis: 'x', width: 82, columns: 2, backBoxId: 151, spacing: 28},

      {axis: 'x', width: 82, columns: 2, backBoxId: 143, spacing: 32},

      {axis: 'x', width: 82, columns: 2, backBoxType: 'MUD RING', spacing: 28},

      {axis: 'x', width: 82, columns: 4, backBoxType: 'MUD RING', spacing: 28},

      {axis: 'x', width: 82, columns: 2, backBoxType: 'MELUS', spacing: 32},

      {axis: 'x', width: 117, columns: 2, backBoxType: 'MELUS', spacing: 36},

      {axis: 'x', width: 117, columns: 2, backBoxType: 'MUD RING', spacing: 36},

      {axis: 'x', width: 117, columns: 3, backBoxId: 801, spacing: 36},
      {axis: 'x', width: 117, columns: 3, backBoxType: 'MELUS', spacing: 28},
      {axis: 'x', width: 117, columns: 3, backBoxType: 'MUD RING', spacing: 28},

      {axis: 'x', width: 117, columns: 4, backBoxId: 801, spacing: 22},

      {axis: 'x', width: 133, hasButton: true, hasToggle: false, columns: 2, backBoxType: 'MELUS', spacing: 42},

      {axis: 'x', width: 133, hasButton: true, hasToggle: false, columns: 3, backBoxType: 'MELUS', spacing: 32},

      {axis: 'x', width: 133, hasButton: true, hasToggle: false, columns: 4, backBoxType: 'MELUS', spacing: 22},

      {axis: 'x', width: 144, columns: 2, backBoxType: 'MELUS', spacing: 46},

      {axis: 'x', width: 144, columns: 3, backBoxType: 'MELUS', spacing: 36},

      {axis: 'x', width: 144, columns: 4, backBoxType: 'MELUS', spacing: 28},

      {axis: 'x', width: 207, columns: 2, backBoxType: 'MELUS', spacing: 76},

      {axis: 'x', width: 207, columns: 3, backBoxType: 'MELUS', spacing: 59},

      {axis: 'x', width: 207, columns: 4, backBoxType: 'MELUS', spacing: 40},

      {axis: 'x', width: 207, columns: 5, backBoxType: 'MELUS', spacing: 32},

      {axis: 'x', width: 207, columns: 6, backBoxType: 'MELUS', spacing: 28},
    ];
  }

  if (productId === 10 || productId === 11) { //elipse and solaris
    spacingRules = [
      {axis: 'y', height: 120, rows: 2, backBoxType: 'MELUS', spacing: 36},

      {axis: 'y', height: 133, rows: 2, backBoxType: 'MELUS', spacing: 46},
      {axis: 'y', height: 133, rows: 3, backBoxType: 'MELUS', spacing: 36},

      {axis: 'y', height: 144, rows: 2, backBoxType: 'MELUS', spacing: 46},
      {axis: 'y', height: 144, rows: 2, backBoxType: 'MUD RING', spacing: 46},

      {axis: 'y', height: 144, rows: 3, backBoxType: 'MELUS', spacing: 36},
      {axis: 'y', height: 144, rows: 3, backBoxType: 'MUD RING', spacing: 36},

      {axis: 'y', height: 175, rows: 3, backBoxType: 'MELUS', spacing: 36},
      {axis: 'y', height: 175, rows: 4, backBoxType: 'MELUS', spacing: 36},

      {axis: 'y', height: 117, rows: 2, backBoxType: 'MELUS', spacing: 36},
      {axis: 'y', height: 117, rows: 2, backBoxType: 'MUD RING', spacing: 36},

      {axis: 'y', height: 207, rows: 2, backBoxType: 'MELUS', spacing: 76},

      {axis: 'y', height: 207, rows: 3, backBoxType: 'MELUS', spacing: 59},

      {axis: 'y', height: 207, rows: 4, backBoxType: 'MELUS', spacing: 40},

      {axis: 'y', height: 207, rows: 5, backBoxType: 'MELUS', spacing: 32},

      {axis: 'y', height: 207, rows: 6, backBoxType: 'MELUS', spacing: 28},

      {axis: 'x', width: 82, columns: 2, backBoxId: 151, spacing: 28},
      {axis: 'x', width: 82, columns: 2, backBoxId: 143, spacing: 32},
      {axis: 'x', width: 82, columns: 2, backBoxType: 'MELUS', spacing: 32},

      {axis: 'x', width: 84, columns: 2, backBoxType: 'MELUS', spacing: 32},

      {axis: 'x', width: 117, columns: 2, backBoxId: 143, spacing: 36},

      {axis: 'x', width: 117, columns: 3, backBoxId: 143, spacing: 28},

      {axis: 'x', width: 144, columns: 2, backBoxType: 'MELUS', spacing: 46},

      {axis: 'x', width: 144, columns: 3, backBoxType: 'MELUS', spacing: 36},

      {axis: 'x', width: 144, columns: 4, backBoxType: 'MELUS', spacing: 28},

      {axis: 'x', width: 207, columns: 3, backBoxType: 'MELUS', spacing: 59},

      {axis: 'x', width: 207, columns: 4, backBoxType: 'MELUS', spacing: 40},

      {axis: 'x', width: 207, columns: 5, backBoxType: 'MELUS', spacing: 32},

      {axis: 'x', width: 207, columns: 6, backBoxType: 'MELUS', spacing: 28},

      {axis: 'x', width: 207, columns: 7, backBoxType: 'MELUS', spacing: 24}
    ];
  }

  var xSpacingRule;
  var ySpacingRule;

    if (productId === 1 || productId === 2 || productId === 5) {
      var xSpacingRule = _$1.find(spacingRules, {axis: 'x', width, backBoxId, columns: totalColumns});
      var ySpacingRule = _$1.find(spacingRules, {axis: 'y', height, backBoxId, rows: totalRows, hasToggle, hasButton});

      if (!xSpacingRule) xSpacingRule = _$1.find(spacingRules, {axis: 'x', width, backBoxType, columns: totalColumns});
      if (!ySpacingRule) ySpacingRule = _$1.find(spacingRules, {axis: 'y', height, backBoxType, rows: totalRows, hasToggle, hasButton});
    }

    if (productId === 10 || productId === 11) {
      var xSpacingRule = _$1.find(spacingRules, {axis: 'x', width, backBoxId, columns: totalColumns});
      var ySpacingRule = _$1.find(spacingRules, {axis: 'y', height, backBoxId, rows: totalRows});

      if (!xSpacingRule) xSpacingRule = _$1.find(spacingRules, {axis: 'x', width, backBoxType, columns: totalColumns});
      if (!ySpacingRule) ySpacingRule = _$1.find(spacingRules, {axis: 'y', height, backBoxType, rows: totalRows});
    }

    return {
      xSpacing: _$1.get(xSpacingRule, 'spacing'),
      ySpacing: _$1.get(ySpacingRule, 'spacing')
    };
  }
}

function __getHelper$s({shouldMemo}, {getProductInstanceWithData, meljac: {getEngravingHeightLimit}}) {
  return function getUsedEngravingCount({productInstance, productOptionsById, productsById, productRulesById, productPropertiesById, productInstancesById, productPropertyId, arrayLength, productProperty}) {
    var usedEngravingCount = 0;
    var usedTextEngravingsCount = 0;
    var usedSymbolEngravingsCount = 0;

    var isUsingCustomEngravings = _$1.has(productInstance, `properties.${productPropertyId}.engravings`);

    if (isUsingCustomEngravings) {
      var productInstanceEngravings = _$1.get(productInstance, `properties.${productPropertyId}.engravings`);

      _$1.times(arrayLength, (mechanismIndex) => {
        var engravingsForMechanism = productInstanceEngravings[mechanismIndex];
        var flatEngravingsForMechanism = _$1.flatMapDeep(engravingsForMechanism);

        _$1.forEach(flatEngravingsForMechanism, (engraving) => {
          if (engraving) {
            if (engraving.text && engraving.text !== '') usedTextEngravingsCount += 1;
            if (engraving.symbol && engraving.symbol !== '') usedSymbolEngravingsCount += 1;
          }
        });
      });
    }
    else {
      var productInstanceEngravingText = _$1.get(productInstance, `properties.${productPropertyId}.text`);

      _$1.times(arrayLength, (inputIndex) => {
        var engravingCountPerMechanism = 2;

        if (productInstance.productId === 1) {
          var ledIndicatorEnabled = _$1.get(productInstance, 'properties[85].optionId') === 519;

          if (ledIndicatorEnabled) engravingCountPerMechanism = 1;
        }

        var activeProductInstanceWithData = getProductInstanceWithData({productInstance}, {
          productOptionsById, productsById, productRulesById, productPropertiesById, productInstancesById
        });

        var engravingHeightIsOverLimit = getEngravingHeightLimit({activeProductInstanceWithData, productInstance, productProperty, inputArrayIndex: inputIndex});

        if (engravingHeightIsOverLimit) engravingCountPerMechanism = 1; //HINT if true only one engraving line per mechanism

        _$1.times(engravingCountPerMechanism, (engravingIndex) => {
          if (_$1.get(productInstanceEngravingText, `${inputIndex}.${engravingIndex}`)) {
            usedTextEngravingsCount += 1;
          }
        });
      });
    }

    usedEngravingCount = usedTextEngravingsCount + usedSymbolEngravingsCount;

    return {usedEngravingCount, usedTextEngravingsCount, usedSymbolEngravingsCount};
  }
}

/*

!!!!!!!! IMPORTANT !!!!!!!!

This set of helpers is heavily optimized - to be performant in both the client browser and our node API/server - it's intended to be both memory and CPU efficient.

Adding new helpers and modifying helpers should be done with extreme care following these requirements - go through this checklist every time you make a change here:

1. All helper files should export a __getHelper fn that returns a fn itself
2. What __getHelper returns should be memoized if shouldMemo is true (see existing examples)
3. Dependencies (helpers that use other helpers) should NOT be imported from other files, but should be descructured from the second argument
4. All imports/helpers below should clearly state their dependencies - if you add a new dependency to a helper, UPDATE THIS FILE
5. Import should be sorted in an order such that the helpers they depend on are above them - so their dependencies get defined before they are used

*/

//HINT deps: NONE should go at top since they may be needed by later helpers, but don't need anything before them
var imports = {
  defaultProductOptionFor: __getHelper$3, //deps: NONE
  getActiveProductOrderStatus: __getHelper$e, //deps: NONE
  getFilteredProductOrderStatuses: __getHelper$i, //deps: NONE
  getNameFor: __getHelper$l, //deps: NONE
  getDefaultDescriptionForProductOrderStatus: __getHelper$k, //deps: NONE
  getLastCompletedProductOrderStatus: __getHelper$f, //deps: NONE
  getPaymentAmountForOrder: __getHelper$h, //deps: NONE
  getShippingCostFor: __getHelper$1, //deps: NONE
  getProductOrderStatusesFor: __getHelper$j, //deps: NONE
  productPropertyClassifierNumberFor: __getHelper$7, //deps: NONE
  productRulesFor: __getHelper$c, //deps: NONE
  toggleProductOrderStatusComplete: __getHelper$g, //deps: NONE
  productPricingRuleVersionFor: __getHelper$6, //deps: NONE

  evaluateProductRule: __getHelper$d, //deps: defaultProductOptionFor
  productOptionsForProperty: __getHelper$4, //deps: productRulesFor, evaluateProductRule
  productOptionForInstanceProperty: __getHelper$9, //deps: productOptionsForProperty
  getArrayQuantityForArrayProductProperty: __getHelper$m, //deps: productOptionForInstanceProperty
  sortedProductPropertiesForProductInstance: __getHelper$8, //deps: productRulesFor, evaluateProductRule
  getProductInstanceWithData: __getHelper$a, //deps: sortedProductPropertiesForProductInstance, productOptionForInstanceProperty
  getProductInstancesWithData: __getHelper$b, //deps: getProductInstanceWithData
};

var meljacImports = {
  getKeypadData: __getHelper$p, //deps: NONE
  getMechanismLayoutData: __getHelper$q, //deps: NONE
  getMechanismSpacings: __getHelper$r, //deps: NONE
  getEngravingHeightLimit: __getHelper$n, //deps: meljac: {getMechanismSpacings, getKeypadData, getMechanismLayoutData}
  getUsedEngravingCount: __getHelper$s, //deps: getProductInstanceWithData, meljac: {getEngravingHeightLimit}
  getCleanedEngravings: __getHelper$o
};

var getProductHelpers = ({shouldMemo = false}) => {
  var products = {};
  var options = {shouldMemo};

  _.forEach(imports, (__getHelper, key) => {
    products[key] = __getHelper(options, products);
  });

  products.meljac = {};

  _.forEach(meljacImports, (__getHelper, key) => {
    products.meljac[key] = __getHelper(options, products);
  });

  //special case where this helper depends on meljac - subsequent helpers depend on it
  products.priceFor = __getHelper$5(options, products); //deps: productPricingRuleVersionFor, defaultProductOptionFor, productPropertyClassifierNumberFor, sortedProductPropertiesForProductInstance, getArrayQuantityForArrayProductProperty, getUsedEngravingCount
  products.calculatePriceForInstance = __getHelper$2(options, products); //deps: priceFor, productPricingRuleVersionFor
  products.getCostsForOrder = __getHelper(options, products); //deps: calculatePriceForInstance, getShippingCostFor

  return products;
};

if (typeof(window) !== 'undefined') {
  var products = getProductHelpers({shouldMemo: true});
}
else {
  var products = getProductHelpers({shouldMemo: false});
}

var products$1 = products;

function getDomainForOrg({org}) {
  return _$1.get(org, `appData.designEngine.domain`, 'https://config.symbolicframeworks.com');
}

function getSupportEmailForOrg({org}) {
  var defaultSupportEmail = 'support@symbolicframeworks.com';

  return _.get(org, 'supportEmail', defaultSupportEmail);
}

var org = {
  getDomainForOrg,
  getSupportEmailForOrg
};

var sf = {api, colors, date, event: libEvent, time, validation, products: products$1, getProductHelpers, org};

exports.api = api;
exports.colors = colors;
exports.date = date;
exports.default = sf;
exports.event = libEvent;
exports.getProductHelpers = getProductHelpers;
exports.memo = memo;
exports.org = org;
exports.products = products$1;
exports.time = time;
exports.validation = validation;
